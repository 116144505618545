<script setup lang='ts'>
import { NScrollbar } from 'naive-ui'
import type { FilterSeriesParams } from '~~/src/server/modules/car/types'
import { LogicDictSeries } from '~~/src/logic/car/dict-series'
const props = withDefaults(defineProps<{
  params: FilterSeriesParams
}>(), {})
const scrollbarRef = ref<typeof NScrollbar | null>(null)

const { list, state, getFilterSeriesData, serverInitHandler } = LogicDictSeries()
async function initHandle() {
  let stop = watch(() => props.params, () => {
    getFilterSeriesData(props.params)
    scrollbarRef.value?.scrollTo({ left: 0 })
  })

  onUnmounted(() => {
    stop?.()
    stop = null!
  })

  await serverInitHandler(props.params)
}
await initHandle()
</script>

<template>
  <NScrollbar ref="scrollbarRef" x-scrollable overflow-y-hidden>
    <page-home-car-mobile-state :state="state" :list="list" @reloadHandle="initHandle">
      <page-home-car-mobile-item v-for="item in list" :key="item.id" :item="item" />
    </page-home-car-mobile-state>
  </NScrollbar>
</template>

<style lang='scss' scoped>
  .recommend {
    --uno: text-center flex-shrink-0 bg-white px-3 pt-2 pb-4 rounded-md shadow-md;

    &-button {
      --uno: border-1 border-gray-200 text-title-600;
      --uno: rounded-sm pl-2 pr-1 flex place-items-center;
    }
  }
</style>
