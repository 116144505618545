<!-- 车辆分类和推荐 -->
<script setup lang='ts'>
import { useCarComponents } from '~~/src/pages/index/hooks/car'

const { isDesktop } = useDevice()
const {
  active: carActive,
  activeType: carActiveType,
  classes: carClasses,
  initHandle: carInitHandler,
} = useCarComponents()

try {
  await carInitHandler()
}
catch (error) {
  recordError(error)
}
</script>

<template>
  <div v-if="isDesktop" class="hidden md:block">
    <page-home-car-pc v-model:active="carActive" v-model:type="carActiveType" :classes="carClasses" />
  </div>
  <div v-else class="px-3 mt-3 md:hidden">
    <page-home-car-mobile :classes="carClasses" />
  </div>
</template>
