<script lang="ts" setup>
import { HOME_SITE_META } from '~~/src/common/site'
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { BannerShowLocation } from '~~/src/server/modules/article/type'

const { siteMeta } = useSite(HOME_SITE_META)
const { isDesktop } = useDevice()
const SWIPER_PARAMS = { bannerShowLocation: BannerShowLocation.HOME, pageNum: 1, pageSize: 8 }
</script>

<template>
  <div layout-default-container md="pt-3 pb-10">
    <utils-site-head :site-meta="siteMeta" />
    <LayoutNavDefaultBack type="search" />
    <!-- A1 -->
    <LazyAdCommonA1 mt-0 :ad-key="AD_KEYS.HOME" :position="AD_POSITION.A1" />
    <page-home-car />
    <div mt-4 flex>
      <div w-full flex-shrink-0 px-3 md="px-0 flex-grow w-0 mr-4">
        <page-swiper :params="SWIPER_PARAMS" :position-key="BannerShowLocation.HOME" :ad-key="AD_KEYS.HOME" ad-position="A3" />
        <utils-quick-tools-menu v-if="!isDesktop" class="mt-4 md:hidden" />
      </div>
      <div v-if="isDesktop" hidden md="block w-78">
        <NuxtLazyHydrate when-visible>
          <utils-quick-tools />
        </NuxtLazyHydrate>
        <ClientOnly>
          <layout-rank-hot-label class="mt-3 md:max-w-78 overflow-hidden" />
        </ClientOnly>
      </div>
    </div>
    <LazyAdCommonA1 mt-4 :ad-key="AD_KEYS.HOME" position="A4" />
    <div flex>
      <div w-full flex-shrink-0 px-3 lg="px-0 flex-grow w-0 mr-4">
        <page-home-news />
      </div>
      <div v-if="isDesktop" hidden lg="block w-78 mt-3">
        <client-only>
          <LazyAdCommonA1 :ad-key="AD_KEYS.HOME" position="A7" />
          <layout-rank-market-article mt-3 />
        </client-only>
      </div>
    </div>
    <NuxtLazyHydrate when-visible>
      <page-home-dealer v-if="isDesktop" />
    </NuxtLazyHydrate>
  </div>
</template>
