<script setup lang='ts'>
import { articleDetailPath, newsHangQingPath } from '@qcwp/common'
import { LogicRecommend } from '~~/src/logic/rank/recommend'
import { ArticleRecomLocationEnum } from '~~/src/server/modules/article/type'

const params = { articleRecomLocation: ArticleRecomLocationEnum.HOME, pageNum: 1, pageSize: 10 }
const { list: hangqingRecommend, clientInitHandler } = LogicRecommend()
clientInitHandler(params)
</script>

<template>
  <n-card v-if="hangqingRecommend && hangqingRecommend.length" title="行情资讯" size="small" rounded-md>
    <template #header>
      <div flex items-center justify-between>
        <div font-black>
          行情资讯
        </div>
        <NuxtLink :to="newsHangQingPath()" class="text-3.5 flex items-center text-title-500 public-hover">
          <span>更多</span>
          <i i-bxs:right-arrow class="text-2.5  ml-1" />
        </NuxtLink>
      </div>
    </template>
    <NuxtLink
      v-for="v, i in hangqingRecommend" :key="i"
      :to="articleDetailPath(v.articleType, { id: v.id, linkUrl: v.linkUrl })"
      class="truncate block w-70 text-4 public-hover leading-8"
    >
      {{ v.title }}
    </NuxtLink>
  </n-card>
</template>
