<script setup lang='ts'>
import type { ComputedRef } from 'vue'
import { carHomePath } from '@qcwp/common'
import type { CarClass, LevelCarClass, PriceCarClass } from '~~/src/pages/index/hooks/car'
import { pxToRem } from '~~/src/common/theme/naive'
import { AD_KEYS } from '~~/src/server/modules/ad/types'
import type { FilterSeriesParams } from '~~/src/server/modules/car/types'

const props = withDefaults(defineProps<{
  classes: CarClass[]
  type?: CarClass['type']
  active?: number
}>(), {
  classes: () => [],
})
const emits = defineEmits({
  'update:type': (value: CarClass['type']) => typeof value === 'string',
})
const tabActiveIndex = ref([0, 0])
const pcClasses = computed(() => props.classes.filter(v => v.platform !== 'mobile')) as ComputedRef<(PriceCarClass | LevelCarClass)[]>

const currentIndex = computed(() => pcClasses.value.findIndex(v => v.type === props.type))
const currentTabIndex = computed(() => tabActiveIndex.value[currentIndex.value])
const params = computed(() => pcClasses.value[currentIndex.value]?.children[currentTabIndex.value]?.params as unknown as FilterSeriesParams)

function activeChangeHandle(value: CarClass['type']) {
  emits('update:type', value)
}
</script>

<template>
  <div
    v-for="item, index in pcClasses" v-show="item.type === props.type"
    :key="item.type"
    class="flex-grow min-w-100 bg-gray-100 pl-1 pr-3 py-0.5 rounded-sm"
  >
    <n-tabs v-model:value="tabActiveIndex[index]" type="bar" animated trigger="click">
      <template #prefix>
        <div class="flex items-center bg-gray-100 cursor-pointer pl-1 pr-1.5 border-r-1 border-gray-200">
          <div
            v-for="item, index in pcClasses" :key="index"
            class="px-2 py-1"
            :class="[item.type === props.type ? 'bg-white rounded-sm' : '']"
            @click="activeChangeHandle(item.type)"
          >
            {{ item.label }}
          </div>
        </div>
      </template>
      <template #suffix>
        <utils-more :to="carHomePath()" title="查看更多" text-title-500 />
      </template>
      <n-tab v-for="v, i in item.children" :key="v.label" :style="{ '--n-pane-padding': `${pxToRem(12)}rem 0` }" :name="i">
        {{ v.label }}
      </n-tab>
    </n-tabs>
  </div>
  <div flex>
    <page-home-car-pc-item :params="params" />
    <!-- A6 -->
    <ad-home-A6 :ad-key="AD_KEYS.HOME" position="A6" />
  </div>
  <page-home-car-pc-recommend />
</template>
