<!-- eslint-disable @typescript-eslint/ban-ts-comment -->
<script setup lang='ts'>
import { LOAD_STATUS, seriesHomePath, seriesParameterPath, seriesPraisePath, seriesPricePath } from '@qcwp/common'
import type { FilterSeriesParams } from '~~/src/server/modules/car/types'
import { LogicDictSeries } from '~~/src/logic/car/dict-series'
const props = withDefaults(defineProps<{
  params?: FilterSeriesParams
}>(), {
  params: () => ({
    hot: '1',
    pageNum: 1,
    pageSize: 18,
  }),
})

const { list, state, getFilterSeriesData, serverInitHandler } = LogicDictSeries()
async function initHandle() {
  let stop = watch(() => props.params, () => {
    getFilterSeriesData(props.params)
  }, { deep: true })

  onUnmounted(() => {
    stop?.()
    stop = null!
  })

  await serverInitHandler(props.params)
}
await initHandle()
</script>

<template>
  <div w-full py-3>
    <div v-if="state === LOAD_STATUS.END && list.length" class="w-full grid grid-cols-6 h-56" lt-tablet="grid-cols-5">
      <div v-for="v in list" :key="v.id" class="text-center px-2 pt-3 border-1 border-transparent">
        <nuxt-link :to="seriesHomePath(v.id)" class="mb-2 flex items-center justify-center px-2 text-title-700 public-hover-primary">
          <h4 text-3.5 font-bold truncate :title="v.carSeriesName">
            {{ v.carSeriesName }}
          </h4>
          <span v-if="v.sellStatus === '新上市'" class="text-2.5 text-white bg-red-500 inline-block px-1 pt-0.5 ml-1 rounded-sm">新</span>
          <span v-if="v.sellStatus === '停售'" class="text-2.5 text-white bg-gray-300 inline-block px-1 pt-0.5 ml-1 rounded-sm">停</span>
        </nuxt-link>
        <div class="flex items-center text-3.25 justify-evenly text-title-500">
          <nuxt-link :to="seriesPricePath(v.id)" public-hover-primary>
            询价
          </nuxt-link>
          <nuxt-link :to="seriesParameterPath(v.id)" public-hover-primary>
            参配
          </nuxt-link>
          <nuxt-link :to="seriesPraisePath(v.id)" public-hover-primary>
            口碑
          </nuxt-link>
        </div>
      </div>
    </div>
    <lazy-base-empty v-if="state === LOAD_STATUS.END && !list.length" icon-class="i-custom-undraw-empty?bg text-37" m-auto />
    <div v-if="[LOAD_STATUS.LOADING, LOAD_STATUS.INIT].includes(state)" class="flex items-center justify-center h-56 text-gray-500">
      <i text-6 mr-3 i-eos-icons:three-dots-loading />
      <span>加载中</span>
    </div>
    <div v-if="state === LOAD_STATUS.ERROR" class="flex flex-col items-center justify-center w-full h-56">
      <div class="flex items-center my-3 text-3 text-slate-4">
        <i class="text-4.5 mr-1 i-iconoir:password-error" />
        <span>加载失败</span>
      </div>
      <button class="border-1 border-slate-2 rounded-md mt-1 px-sm py-1" @click="() => getFilterSeriesData(props.params)">
        重新加载
      </button>
    </div>
  </div>
</template>
