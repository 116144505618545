<script setup lang='ts'>
import { carZhHomePath } from '@qcwp/common'
import type { PriceChildren } from '~~/src/pages/index/hooks/car'
const props = withDefaults(defineProps<{
  children: PriceChildren[]
}>(), {

})
const active = ref(0)
const params = computed(() => props.children[active.value]?.params ?? {})
</script>

<template>
  <div flex items-center justify-between px-3 mt-2>
    <n-scrollbar pb-3 x-scrollable flex-grow w-0 pr-3>
      <div flex items-center>
        <div v-for="val, idx in children" :key="idx" class="item-button flex-shrink-0 mr-2" :class="{ active: active === idx }" @click="active = idx">
          {{ val.label }}
        </div>
      </div>
    </n-scrollbar>
    <utils-more pb-2 flex-shrink-0 :to="carZhHomePath()" />
  </div>
  <div class="h-40">
    <page-home-car-mobile-filter :params="params" />
  </div>
</template>

<style lang='scss' scoped>
.item-button {
  --uno: border-1 border-gray-200 bg-gray-100 text-title-600;
  --uno: rounded-md px-2.5 py-0.5 grid place-items-center;

  &.active {
    --uno: bg-blue-400 text-white;
  }
}
</style>
