import { LOAD_STATUS } from '@qcwp/common'
import { getFilterSeries } from '~~/src/server/modules/car'
import type { FilterSeriesParams, SeriesResponse } from '~~/src/server/modules/car/types'

export function LogicDictSeries() {
  const listMap = reactive(new WeakMap())
  const currentParams = ref<FilterSeriesParams | null>(null)
  const list = computed<SeriesResponse[]>(() => currentParams.value ? listMap.get(currentParams.value)?.list ?? [] : [])
  const state = computed<LOAD_STATUS>(() => currentParams.value ? listMap.get(currentParams.value)?.state ?? LOAD_STATUS.INIT : LOAD_STATUS.INIT)

  function updateParams(params: any) {
    currentParams.value = params
  }
  function setListMapState(key: FilterSeriesParams, state: LOAD_STATUS) {
    listMap.set(key, { state, list: listMap.get(key)?.list ?? [] })
  }
  async function serverInitHandler(params: FilterSeriesParams) {
    updateParams(params)
    const asyncData = await useAsyncData('SERIES_RECOMMEND', () => getFilterSeries(params))
    const { data, error } = useNuxtAsyncHandle(asyncData)
    if (data && !error.value)
      listMap.set(params, { state: LOAD_STATUS.END, list: data.list ?? [] })
    else if (error.value)
      listMap.set(params, { state: LOAD_STATUS.ERROR, list: [] })
  }
  async function getFilterSeriesData(params: FilterSeriesParams) {
    updateParams(params)
    const key = params
    if (!key || (listMap.has(key) && listMap.get(key).state === LOAD_STATUS.END))
      return
    setListMapState(key, LOAD_STATUS.LOADING)
    try {
      const { data } = await getFilterSeries(key)
      listMap.set(key, { state: LOAD_STATUS.END, list: data.list ?? [] })
    }
    catch (error) {
      key && listMap.set(key, { state: LOAD_STATUS.ERROR, list: [] })
    }
  }

  return {
    listMap,
    list,
    state,
    getFilterSeriesData,
    serverInitHandler,
  }
}
