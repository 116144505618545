<script setup lang='ts'>
import { LOAD_STATUS, carHomePath, imageTransform } from '@qcwp/common'
import { LogicBrand } from '~~/src/logic/car/brand'

const { brands, serverInitHandler } = LogicBrand()

const state = ref(LOAD_STATUS.INIT)
async function initHandle() {
  state.value = LOAD_STATUS.LOADING
  const { error } = await serverInitHandler({ recomm: '1' })
  if (error.value) {
    state.value = LOAD_STATUS.ERROR
    return
  }
  brands.value = brands.value.slice(0, 10)
  state.value = LOAD_STATUS.END
}
await initHandle()
</script>

<template>
  <page-home-car-mobile-state :state="state" :list="brands" height-class="h-40" @reloadHandle="initHandle">
    <div class="w-full grid grid-cols-5 gap-2 px-2 mt-1">
      <nuxt-link
        v-for="item in brands" :key="item.id" :to="carHomePath(item.id)"
        class="grid place-items-center bg-white px-1 pb-1 rounded-md border-1 border-gray-100"
      >
        <div class="w-10 h-10 p-1">
          <base-image
            class="w-full h-full preview-disabled "
            :lazy="true"
            object-fit="contain"
            :alt="`${item.brandName}LOGO`"
            :src="imageTransform(item.coverImg, { width: 50, height: 50, format: 'webp' })"
          />
        </div>
        <h4 text-center truncate text-3 w-full>
          {{ item.brandName }}
        </h4>
      </nuxt-link>
    </div>
  </page-home-car-mobile-state>
</template>
