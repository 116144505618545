<script setup lang='ts'>
import { LogicHotLabel } from '~~/src/logic/rank/hot-label'
const colors = [
  'bg-red-400',
  'bg-blue-500',
  'bg-yellow-400',
  'bg-red-500',
  'bg-pink-400',
  'bg-green-500',
  'bg-blue-400',
  'bg-yellow-500',
  'bg-green-400',
  'bg-pink-500',
]

// 热门标签
const { labels: hotTags, clientInitHandler } = LogicHotLabel()
clientInitHandler()
</script>

<template>
  <n-card
    v-if="hotTags && hotTags.length"
    title="热门标签" embedded size="small"
    header-style="--n-title-font-weight: bold;" class="w-full rounded-md"
  >
    <div v-if="hotTags && hotTags.length" flex="~ wrap" class="w-full content-start h-23 overflow-hidden">
      <NuxtLink
        v-for="v, i in hotTags" :key="i" :to="v.linkUrl"
        class="h-7 px-2 py-1 text-white rounded-sm mr-1 mb-1 block max-w-100% label"
        :class="[colors[i % colors.length]]"
      >
        <n-ellipsis>{{ v.label }}</n-ellipsis>
      </NuxtLink>
    </div>
    <base-empty v-else />
  </n-card>
</template>
