import { searchPath } from '@qcwp/common'
import { isArray, isClient } from '@qcwp/utils'
import type { HotTagsResponse, TagsSearchType } from '~~/src/server/modules/article/type'
import { getHotTags } from '~~/src/server/modules/article'
/**
 * 热门标签
 */
export function LogicHotLabel() {
  const labels = ref<HotTagsResponse[]>()

  function format(list: HotTagsResponse[] = []) {
    if (!list || !isArray(list))
      return []
    return list.map(v => ({
      ...v,
      linkUrl: generateUrl(v.tagsSearchType, v.linkUrl),
    }))
  }

  function generateUrl(tagsSearchType: TagsSearchType, linkUrl: string): string {
    if (tagsSearchType === 1)
      return searchPath(linkUrl)
    else
      return linkUrl
  }

  async function clientInitHandler() {
    if (!isClient)
      return
    const { data = [] } = await fetchErrorCollect(getHotTags) || {}
    labels.value = format(data)
  }
  return {
    labels,
    clientInitHandler,
  }
}
