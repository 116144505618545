import { getDealerList } from '~~/src/server/modules/car'
import type { DealerListResponse } from '~~/src/server/modules/car/types'

export function LogicDealerRecommendList() {
  const list = ref<DealerListResponse[]>([])

  async function serverInitHandler() {
    const asyncData = await useAsyncData('DEALER_RECOMMEND', () => getDealerList({ pageNum: 1, pageSize: 20 }))
    const { data } = useNuxtAsyncHandle(asyncData)
    list.value.push(...(data?.list || []))
  }
  return {
    list,
    serverInitHandler,
  }
}
