<script setup lang='ts'>
import { dealerDetailPath, dealerFindPath, imageTransform } from '@qcwp/common'
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'
import { LogicDealerRecommendList } from '~~/src/logic/dealer/recommend'
const { adData } = getAdComponentData(AD_KEYS.HOME, AD_POSITION.A8)
const { list: dealerRecommend, serverInitHandler: dealerRecommendServerInitHandler } = LogicDealerRecommendList()

try {
  await dealerRecommendServerInitHandler()
}
catch (error) {
  recordError(error)
}
</script>

<template>
  <div class="md:block hidden px-3 mt-4 md:px-0 bg-gradient-to-b from-blue-200 to-blue-50 py-4 pb-2 rounded-md">
    <div flex items-center justify-between px-4>
      <n-gradient-text type="info" text-4 md:text-5 font-black>
        经销商推荐
      </n-gradient-text>
      <nuxt-link flex items-center text-title-700 public-hover :to="dealerFindPath()">
        <span>更多经销商</span><span text-3 inline-block i-bxs:right-arrow />
      </nuxt-link>
    </div>
    <div v-if="(dealerRecommend && dealerRecommend.length)" class="flex p-4 overflow-auto scrollbar-default">
      <lazy-ad-home-A8 v-if="adData" />
      <div
        v-for="v in dealerRecommend"
        :key="v.id"
        class="w-42 bg-white rounded-md px-2 py-5 mr-4 relative shadow-md flex-shrink-0 group"
      >
        <nuxt-link flex="~ col" items-center gap-1 :to="dealerDetailPath(v.id)">
          <img
            class="w-14 h-14"
            loading="lazy"
            :alt="v.dealerName"
            :src="imageTransform(v.dealerLogo, { width: 200, height: 200, format: 'webp' })"
          >
          <n-ellipsis text-4 font-black :tooltip="true">
            {{ v.dealerName }}
          </n-ellipsis>
          <div>主营:{{ v.majorCarName }}</div>
          <div text-error text-3>
            电话：{{ v.dealerPhone }}
          </div>
        </nuxt-link>
        <ClientOnly>
          <utils-copy lt-md="hidden" md="hidden group-hover:block" z-9 text-gray-500 absolute bottom-1 right-1 text-5 :content="v.dealerPhone" />
        </ClientOnly>
      </div>
    </div>
    <base-empty v-else />
  </div>
</template>
