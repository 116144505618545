<script setup lang='ts'>
import { PaginationStatus, newsHomePath } from '@qcwp/common'
import { isClient } from '@qcwp/utils'
import { formatJumpLocation } from '~~/src/pages/news/hooks/jump'
import { useCustomInfiniteScroll } from '~~/src/components/base/list/hooks/useInfiniteScroll'
import { AD_KEYS, AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'
import { moduleNewComponent } from '~~/src/pages/index/hooks/newComponent'

const { isMobileOrTablet } = useDevice()
const { adData } = getAdComponentData(AD_KEYS.HOME, AD_POSITION.A5)

const {
  classes,
  currentCol,
  getListHandler,
  serverInitHandler,
  goMorePage,
} = moduleNewComponent()

const itemsRef = ref()
if (false && isMobileOrTablet && isClient) {
  let watchStopHandle: any = null
  let watchEffectStopHandle: any = null
  let scrollStop: any = null

  function removeScrollEvent() {
    watchEffectStopHandle?.()
    scrollStop?.()
  }
  const removeWatchHandler = () => {
    watchStopHandle?.()
    removeScrollEvent()

    watchStopHandle = null
    watchEffectStopHandle = null
    scrollStop = null
  }
  const addWatchHandler = () => {
    removeWatchHandler()
    watchStopHandle = watch(itemsRef, () => {
      if (!itemsRef.value)
        return
      removeScrollEvent?.()
      watchEffectStopHandle = watchEffect(() => {
        scrollStop = useCustomInfiniteScroll(
          isClient ? document : undefined,
          () => getListHandler(),
          { distance: 10, direction: 'bottom' },
        )
      })
    }, { immediate: true })
  }

  addWatchHandler()
  onActivated(addWatchHandler)
  onDeactivated(removeWatchHandler)
  onUnmounted(removeWatchHandler)
}

try {
  await serverInitHandler()
}
catch (error) {
  recordError(error)
}
</script>

<template>
  <n-tabs v-model:value="currentCol" type="line" animated>
    <template #suffix>
      <utils-more :to="newsHomePath()" text-title-500 />
    </template>
    <n-tab-pane
      v-for="item, index in classes"
      :key="index"
      :name="index"
      :tab="item.label"
      display-directive="show"
      :style="{ '--n-pane-padding': '0' }"
    >
      <div v-if="(!item.list || !item.list.length) && ![PaginationStatus.INIT, PaginationStatus.LOADING].includes(unref(item.pagination.pagination).status)">
        <base-empty />
      </div>
      <div v-else ref="itemsRef">
        <div md="grid grid-cols-3 gap-3 py-3">
          <lazy-ad-home-A5 v-if="index === 0 && adData && adData.length" />
          <NuxtLazyHydrate when-visible>
            <layout-article-item
              v-for="v, i in item.list" :key="v.id"
              py-3
              border="b-1 gray-100" md="py-0 border-b-0"
              :article="v"
              :location="formatJumpLocation({
                article: v,
                pageSize: 12,
                index: i,
                type: item.type,
                params: item.params,
                list: item.list,
              })"
            />
          </NuxtLazyHydrate>
        </div>
        <n-button
          v-if="!item.toMorePage || isMobileOrTablet"
          block strong secondary
          :loading="unref(item.pagination.pagination).status === PaginationStatus.LOADING"
          :disabled="unref(item.pagination.pagination).status === PaginationStatus.NO_MORE"
          @click="getListHandler"
        >
          {{
            unref(item.pagination.pagination).status !== PaginationStatus.NO_MORE ? '加载更多' : '没有更多数据了'
          }}
        </n-button>
        <n-button v-else block strong secondary @click="goMorePage">
          查看更多
        </n-button>
      </div>
    </n-tab-pane>
  </n-tabs>
</template>
