import { LOAD_STATUS } from '@qcwp/common'
import { isArray, isNumber } from '@qcwp/utils'
import type { RadioGroupOption } from '~~/src/components/layout/filter/hooks/types'
import { LogicDict } from '~~/src/logic/common/dict'
import { callWithNuxt } from '#app'

export interface TextCarChildren {
  label: string
}
export interface BrandCarChildren extends TextCarChildren {
  src: string
  brandId: number
}
export interface RecommendCarChildren extends TextCarChildren {
  src: string
  seriesId: number
}
export interface PriceChildren extends TextCarChildren {
  params: Record<string, unknown>
}
export interface BaseCarClass<T = TextCarChildren, R = 'text'> {
  type: R
  platform: 'mobile' | 'pc' | 'all'
  label: string
  children: T[]
}
export type RecommendCarClass = BaseCarClass<RecommendCarChildren, 'recommend'>
export type PriceCarClass = BaseCarClass<PriceChildren, 'price'>
export type LevelCarClass = BaseCarClass<PriceChildren, 'level'>
export type CarClass = BaseCarClass
| RecommendCarClass
| BaseCarClass<BrandCarChildren, 'brand'>
| PriceCarClass
| LevelCarClass

export function useCarComponents() {
  const { isDesktop } = useDevice()
  const { getDict, formatDict } = LogicDict()
  // 当前活动switch
  const active = ref(0)
  const activeType = ref(isDesktop ? 'level' : 'hotSeries')

  const classes = reactive<CarClass[]>([
    {
      label: '热门品牌',
      platform: 'mobile',
      type: 'brand',
      children: [],
    },
    {
      label: '按能源',
      type: 'level',
      platform: 'all',
      children: [],
    },
    {
      label: '按价格',
      type: 'price',
      platform: 'all',
      children: [],
    },
  ])
  const options = reactive<Record<string, RadioGroupOption[]>>({
    levelOptions: [],
    priceOptions: [],
  })
  /**
   * 初始化tab
   */
  const nuxtApp = useNuxtApp()
  async function optionInitHandle() {
    const promises = [
      callWithNuxt(nuxtApp, getDict, ['car_energy_type']),
      callWithNuxt(nuxtApp, getDict, ['series_price_range']),
    ]
    const [levelOptionsDict, priceOptionsDict] = await Promise.all(promises)
    // 级别
    if (levelOptionsDict) {
      options.levelOptions = formatDict(levelOptionsDict)
      changeClass('level', options.levelOptions)
    }
    // 价格
    if (priceOptionsDict) {
      options.priceOptions = formatDict(priceOptionsDict)
      changeClass('price', options.priceOptions)
    }
    // console.log(options, classes)
  }
  // 修改类别
  function changeClass(type: CarClass['type'], data: RadioGroupOption[] = []) {
    const index = getClassIndex(type)
    if (!isNumber(index))
      return

    const children = []
    for (const item of data) {
      children.push({
        label: item.label,
        params: generateParams(type, item),
        list: [],
        state: LOAD_STATUS.INIT,
      })
    }
    classes[index].children = children
  }
  /**
   * 获取筛选类别在全部类别中的索引
   * @param type CarClass['type']
   */
  function getClassIndex(type: CarClass['type']): number | undefined {
    const index = classes.findIndex(v => v.type === type)
    return index === -1 ? undefined : index
  }

  async function initHandle() {
    const promises = [optionInitHandle()]

    await Promise.all(promises)
  }
  return {
    active,
    activeType,
    classes,

    initHandle,
  }
}

/**
 *
 * 按对应类别生成车系筛选条件
 * @param type 类别 CarClass['type']
 * @param item RadioGroupOption
 * @returns minPrice?: number;maxPrice?: number
 */
export function generateParams(type: CarClass['type'], item: RadioGroupOption) {
  switch (type) {
    case 'price':
      return { ...generatePriceOptionParams(item), pageSize: 18, pageNum: 1 }
    case 'level':
      return { ...generateLevelOptionParams(item), pageSize: 18, pageNum: 1 }
  }
}
/**
 * 生成车系筛选条件 - 按价格
 * @param item RadioGroupOption
 * @returns minPrice?: number;maxPrice?: number
 */
export function generatePriceOptionParams(item: RadioGroupOption): { minPrice?: number;maxPrice?: number } {
  let params: Record<string, unknown> = {}
  let value = 0
  try {
    value = JSON.parse(item.value ?? '0')
  }
  catch {}
  if (!isArray(value))
    params.minPrice = 0
  else if (isArray(value) && value.length === 1)
    params.minPrice = value[0]
  else
    params = { minPrice: value[0], maxPrice: value[1] }
  return params
}
/**
 * 生成车系筛选条件 - 按级别
 * @param item RadioGroupOption
 * @returns minPrice?: number;maxPrice?: number
 */
export function generateLevelOptionParams(item: RadioGroupOption): { seriesGrade?: string; hot?: '0' | '1' } {
  if (item?.value === '0')
    return { hot: '1' }

  return JSON.parse(JSON.stringify({ seriesEnergyType: item?.value }))
}
